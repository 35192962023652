/* Colors */
:root {
  --main: #028383;
  --gray: #707070;
  --moon-gray: #949494;
  --light-gray: #eee;
  --near-white: #f8f8fa;
  --bg-tb-gray: #fdfdfc;
}

/* Text colors */
.dark-teal {
  color: #1e6a77;
}
.teal {
  color: var(--main);
}
.gray {
  color: var(--gray) !important;
}
.dark-red {
  color: #C9030D
}
.text-black {
  color: #00101d;
}
.text-red {
  color: #D8030E;
}
.text-gold {
  color: #916800;
}

/* Background colors */
.bg-teal {
  background-color: var(--main);
}

.bg-tb-gray {
  background-color: var(--bg-tb-gray);
}

.bg-near-white {
  background-color: var(--near-white) !important;
}

.bg-blue {
  background-color: #3374c9;
}
.bg-red {
  background-color: #D8030E;
}
.bg-orange {
  background-color: #dd3308;
}
