@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
.hover-border:hover {
  box-shadow: 0px 0px 0px 2px var(--main);
  border: 1px solid var(--main);
  transition: all 0.3s ease;
}

.hover-border-moon-gray:hover {
  border: 1px solid var(--moon-gray);
  transition: all 0.3s ease;
}

.hover-bg-teal:hover {
  background-color: var(--main);
  transition: all 0.3s ease;
}

.hover-bg-light-gray:hover {
  background-color: var(--light-gray) !important;
}

.hover-teal:hover,
.hover-teal:focus {
  color: #015b5b;
  transition: all 0.3s ease;
}

.grow {
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.grow:hover,
.grow:focus {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.grow:active {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.animate-background {
  transition: background-color 1s ease;
}

.tealbotHelp-enter {
  opacity: 0.01;
}
.tealbotHelp-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}
.tealbotHelp-exit {
  opacity: 1;
}
.tealbotHelp-exit-active {
  opacity: 0.01;
  -webkit-transform: scale(0.1, 0.1);
          transform: scale(0.1, 0.1);
  transition: all 500ms ease-in;
}

.parallax {
  background-attachment: local;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: all 500ms ease-in;
}

.loading {
  position: relative;
  overflow: hidden;
}
.loading::after {
  content: '';
  display: block;
  background-color: #dddfe2;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translateX(0);
          transform: translateX(0);
  -webkit-animation: 1.5s loading-placeholder ease-in-out infinite;
          animation: 1.5s loading-placeholder ease-in-out infinite;
}

@-webkit-keyframes loading-placeholder {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

@keyframes loading-placeholder {
  0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
}

.hover-swing-child:hover > .swing {
  -webkit-animation: 1.5s swing ease-in-out infinite;
          animation: 1.5s swing ease-in-out infinite;
}

.hover-swing:hover {
  -webkit-animation: 1.5s swing ease-in-out infinite;
          animation: 1.5s swing ease-in-out infinite;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes swing {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

.char1 {
  -webkit-transform: rotate(-65deg);
          transform: rotate(-65deg);
  left: 65px;
  top: 20px;
  position: absolute;
}
.char2 {
  -webkit-transform: rotate(-55deg);
          transform: rotate(-55deg);
  left: 77px;
  top: 7px;
  position: absolute;
}
.char3 {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 87px;
  top: -2px;
  position: absolute;
}
.char4 {
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
  left: 95px;
  top: -8px;
  position: absolute;
}
.char5 {
  -webkit-transform: rotate(-25deg);
          transform: rotate(-25deg);
  left: 106px;
  top: -13px;
  position: absolute;
}

.char6 {
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  left: 120px;
  top: -17px;
  position: absolute;
}
.char7 {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  left: 133px;
  top: -18px;
  position: absolute;
}

.char9 {
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
  left: 160px;
  top: -14px;
  position: absolute;
}
.char10 {
  -webkit-transform: rotate(36deg);
          transform: rotate(36deg);
  left: 173px;
  top: -9px;
  position: absolute;
}
.char11 {
  -webkit-transform: rotate(44deg);
          transform: rotate(44deg);
  left: 183px;
  top: -3px;
  position: absolute;
}
.char12 {
  -webkit-transform: rotate(47deg);
          transform: rotate(47deg);
  left: 190px;
  top: 4px;
  position: absolute;
}
.char13 {
  -webkit-transform: rotate(59deg);
          transform: rotate(59deg);
  left: 198px;
  top: 12px;
  position: absolute;
}

body {
  background-color: var(--bg-tb-gray);
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
}

a {
  color: var(--main);
  text-decoration: none;
}

.scrollbar::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 0.25em;
}

.rte-readonly-wrapper > div {
  font-family: 'Open Sans', sans-serif;
  border: none;
  color: #555;
}

.rte-readonly-wrapper .public-DraftEditor-content {
  padding: 0;
  font-size: 0.875rem;
}

.react-rte-editor .public-DraftEditor-content {
  min-height: 100px;
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 600px) {
  .react-rte-editor .public-DraftEditor-content {
    height: 150px;
  }
}

.react-rte-editor .public-DraftEditorPlaceholder-root {
  font-family: sans-serif;
}

.focus-outline:focus-visible {
  outline: auto black;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Colors */
:root {
  --main: #028383;
  --gray: #707070;
  --moon-gray: #949494;
  --light-gray: #eee;
  --near-white: #f8f8fa;
  --bg-tb-gray: #fdfdfc;
}

/* Text colors */
.dark-teal {
  color: #1e6a77;
}
.teal {
  color: #028383;
  color: var(--main);
}
.gray {
  color: #707070 !important;
  color: var(--gray) !important;
}
.dark-red {
  color: #C9030D
}
.text-black {
  color: #00101d;
}
.text-red {
  color: #D8030E;
}
.text-gold {
  color: #916800;
}

/* Background colors */
.bg-teal {
  background-color: #028383;
  background-color: var(--main);
}

.bg-tb-gray {
  background-color: #fdfdfc;
  background-color: var(--bg-tb-gray);
}

.bg-near-white {
  background-color: #f8f8fa !important;
  background-color: var(--near-white) !important;
}

.bg-blue {
  background-color: #3374c9;
}
.bg-red {
  background-color: #D8030E;
}
.bg-orange {
  background-color: #dd3308;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 0.5;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 0.5;
}

input::placeholder,
textarea::placeholder {
  opacity: 0.5;
}

input[type='search']::-webkit-input-placeholder {
  opacity: 1;
}

input[type='search']:-ms-input-placeholder {
  opacity: 1;
}

input[type='search']::placeholder {
  opacity: 1;
}
textarea {
  resize: none;
}

.vet-text::-webkit-input-placeholder {
  font-style: italic;
}

.vet-text:-ms-input-placeholder {
  font-style: italic;
}

.vet-text::placeholder {
  font-style: italic;
}

input:disabled,
select:disabled {
  background: #f0f0f0;
}

input.focus-ba:focus {
  border-style: solid;
  border-width: 1px;
}

input.focus-bg-white:focus {
  background: white;
}

.f8 {
  font-size: 0.75rem !important;
}

.f9 {
  font-size: 0.65rem;
}

.h1-5 {
  height: 1.5rem;
}

.h2-5 {
  height: 2.5rem;
}

.w-5 {
  width: 0.7rem;
}

.w1-5 {
  width: 1.5rem;
}

.w4-5 {
  width: 10rem;
}

.w3-5 {
  width: 5.5rem;
}

.w2-5 {
  width: 2.5rem;
}

.mw4-5 {
  max-width: 10rem;
}

.pv2-5 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.ph2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pa2-5 {
  padding: 0.75rem;
}

.f7 {
  font-size: 0.825rem !important;
}

.pa3-5 {
  padding: 1.5rem;
}

.pv3-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.ph3-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pt3-5 {
  padding-top: 1.5rem;
}

.pb3-5 {
  padding-bottom: 1.5rem;
}

.pl3-5 {
  padding-left: 1.5rem;
}

.pr3-5 {
  padding-right: 1.5rem;
}

.mt3-5 {
  margin-top: 1.5rem;
}

.nl3-5 {
  margin-left: -1.5rem;
}

.nr3-5 {
  margin-right: -1.5rem;
}

.nb3-5 {
  margin-bottom: -1.5rem;
}

.mw8 {
  max-width: 71.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mr-8 {
  margin-right: 8px;
}

@media screen and (min-width: 30em) {
  .pr3-5-ns {
    padding-right: 1.5rem !important;
  }

  .w3-5-ns {
    width: 5.5rem !important;
  }

  .w4-5-ns {
    width: 10rem !important;
  }
}

@media screen and (max-width: 60em) and (min-width: 30em) {
  .w4-5-m {
    width: 10rem !important;
  }
  .w5-m {
    width: 16rem !important;
  }
  .w6-m {
    width: 32rem !important;
  }
}

@media screen and (min-width: 60em) {
  .mw4-5-l {
    max-width: 10rem;
  }
  .w2-5-l {
    width: 2.5rem !important;
  }
}

select::-ms-expand {
  display: none;
}

input[type='file'] {
  background: white;
}

/* Main */

