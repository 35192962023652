.f8 {
  font-size: 0.75rem !important;
}

.f9 {
  font-size: 0.65rem;
}

.h1-5 {
  height: 1.5rem;
}

.h2-5 {
  height: 2.5rem;
}

.w-5 {
  width: 0.7rem;
}

.w1-5 {
  width: 1.5rem;
}

.w4-5 {
  width: 10rem;
}

.w3-5 {
  width: 5.5rem;
}

.w2-5 {
  width: 2.5rem;
}

.mw4-5 {
  max-width: 10rem;
}

.pv2-5 {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.ph2-5 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.pa2-5 {
  padding: 0.75rem;
}

.f7 {
  font-size: 0.825rem !important;
}

.pa3-5 {
  padding: 1.5rem;
}

.pv3-5 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.ph3-5 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.pt3-5 {
  padding-top: 1.5rem;
}

.pb3-5 {
  padding-bottom: 1.5rem;
}

.pl3-5 {
  padding-left: 1.5rem;
}

.pr3-5 {
  padding-right: 1.5rem;
}

.mt3-5 {
  margin-top: 1.5rem;
}

.nl3-5 {
  margin-left: -1.5rem;
}

.nr3-5 {
  margin-right: -1.5rem;
}

.nb3-5 {
  margin-bottom: -1.5rem;
}

.mw8 {
  max-width: 71.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mr-8 {
  margin-right: 8px;
}

@media screen and (min-width: 30em) {
  .pr3-5-ns {
    padding-right: 1.5rem !important;
  }

  .w3-5-ns {
    width: 5.5rem !important;
  }

  .w4-5-ns {
    width: 10rem !important;
  }
}

@media screen and (max-width: 60em) and (min-width: 30em) {
  .w4-5-m {
    width: 10rem !important;
  }
  .w5-m {
    width: 16rem !important;
  }
  .w6-m {
    width: 32rem !important;
  }
}

@media screen and (min-width: 60em) {
  .mw4-5-l {
    max-width: 10rem;
  }
  .w2-5-l {
    width: 2.5rem !important;
  }
}
